import { deviceWidth } from 'constants/responsive'
import dayjs from 'dayjs'
import { usePlayer } from 'hooks/usePlayer'
import { QueryInfo } from 'hooks/useQueryInfo'

import { useLazyQuery } from '@apollo/client'

import { ShiurimAutoPlayInfo, ShiurimItem } from '../../Cards/ShiurimCard'
import { PlayIcon } from '../../Player/icons'
import { Button, Loader, Wrapper } from './styled'

function PlayAllShiursButton({ query, variables }: QueryInfo) {
  const { bulkAddToQueue, play } = usePlayer()

  const [queryShiurs, { loading }] = useLazyQuery(query)

  const onPlay = async () => {
    const { data } = await queryShiurs({
      variables,
    })
    const keyName = Object.keys(data)[0]
    const items = data[keyName].items
    const unplayedItems = items.filter((el: ShiurimItem) =>
      el.listenedDuration ? dayjs.duration(el.duration - el.listenedDuration).asSeconds() > 15 : true
    )

    const queueItems = [...unplayedItems]
    queueItems.shift()

    const shiurQueryInfo: QueryInfo = { query, variables }
    const itemsWithShiurimAutoPlayInfo = queueItems.map((el, index) => {
      const shiurAutoPlayInfo: ShiurimAutoPlayInfo = { ...shiurQueryInfo, index }
      return { ...el, shiurAutoPlayInfo }
    })

    if (itemsWithShiurimAutoPlayInfo.length) bulkAddToQueue(itemsWithShiurimAutoPlayInfo)

    if (unplayedItems.length) {
      await play(unplayedItems[0])
    }
  }

  const isMobile = window.innerWidth < Number(deviceWidth.tablet.match(/(\d+)/)[0])
  return (
    <Wrapper>
      <Button type="primary" size="middle" onClick={onPlay}>
        {!isMobile && 'Play all'}
        {loading ? <Loader size="small" /> : <PlayIcon />}
      </Button>
    </Wrapper>
  )
}

export default PlayAllShiursButton
